// Server-side error reporting adapted from Sentry's example:
// https://github.com/vercel/next.js/tree/canary/examples/with-sentry

import React from "react";
import NextErrorComponent, { ErrorProps } from "next/error";
import { ExtendedNextPage } from "components/common/types";
import { reportError } from "lib/errors/errors";

type CustomErrorProps = ErrorProps & { hasGetInitialPropsRun?: boolean; err?: Error };
const MyError: ExtendedNextPage<CustomErrorProps> = ({
  statusCode,
  hasGetInitialPropsRun,
  err,
}) => {
  if (!hasGetInitialPropsRun && err) {
    reportError(err, {}, true);
  }

  return <NextErrorComponent statusCode={statusCode} />;
};

MyError.getInitialProps = async (ctx) => {
  const errorInitialProps: CustomErrorProps = await NextErrorComponent.getInitialProps(ctx);

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  if (ctx.err) {
    reportError(ctx.err, {}, true);

    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This can be caused by
  // a falsy value being thrown e.g. throw undefined
  return errorInitialProps;
};

export default MyError;
